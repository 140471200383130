<template>
  <div class="classHour" v-loading="loading">
    <el-tabs v-model="activeName"  class="el-nav" @tab-click="handleClick">
      <div class="el-nav-header flex-nowrap">
        <img :src="courseInfo.pc_cover_picture" class="table-img" alt="">
        <div class="header-r">
          <div class="table-item-name">{{ courseInfo.title }}</div>
          <div class="table-item-times flex-nowrap">
            <div class="table-item-time" v-if="!courseInfo.is_share">学习人数：{{ courseInfo.buy_num + courseInfo.learn_num > 9999 ? ((courseInfo.buy_num+ courseInfo.learn_num) / 10000 ).toFixed(2) + 'W': (courseInfo.buy_num+ courseInfo.learn_num) }}人学习<span style="color: #D0D6DE;margin: 0 8px">|</span></div>
            <div class="table-item-time">课程时长：{{ courseInfo.length ? $carbon.formatSeconds(courseInfo.length) : 0 }}</div>
          </div>
          <div>
            <el-tag
                style="margin-right: 20px;background:rgba(255, 165, 9, 0.1);border: unset;color: #FFA509;border-radius: 10px;padding: 0 10px;"
                v-for="(item,index) in courseInfo.tags"
                :key="index"
                size="mini"
                type="success"
                effect="dark">
              {{ item.name }}
            </el-tag>
          </div>
        </div>
      </div>
      <el-tab-pane label="课时管理" class="first" name="chapters"></el-tab-pane>
      <el-tab-pane label="课程媒体" class="two" name="media"></el-tab-pane>
      <el-tab-pane label="资料管理" class="three" name="materials"></el-tab-pane>
      <el-tab-pane :label="courseInfo.comment_count ? (courseInfo.comment_count > 99 ?  '留言 '+'99+' : '留言 '+courseInfo.comment_count) : '留言 '+ 0 " class="four" name="interact"  v-if="!courseInfo.is_share"></el-tab-pane>
      <el-tab-pane label="学员管理" class="five" name="student"  v-if="!courseInfo.is_share">
        <!--        搜索查询-->
        <div class="search space-between">
          <div class="flex-nowrap">
            <el-select v-model="options" placeholder="标题" class="search-type" >
              <el-option
                  v-for="item in optionsData"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
            <el-input v-model="keywords" placeholder="请输入内容" class="search-input"></el-input>
            <el-button type="primary" class="search-btn" @click="searchSubmit">查询</el-button>
            <el-button class="search-btn reset" @click="resetFun">重置</el-button>
          </div>
        </div>

        <div class="total space-between">
          <div class="total-left">共有 <span class="total-left-text">{{studentInfo.meta ? studentInfo.meta.total : 0}}</span> 条数据</div>

        </div>

        <!--        表格-->
        <div v-if="studentInfo.data && studentInfo.data.length">
          <el-table
              class="table"
              ref="multipleTable"
              :data="studentInfo.data"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="{background:'#F6F8F9',color: '#1C1F21',fontWeight:'400'}"
              border>

            <el-table-column
                label="编号"
                width="72">
              <template  slot-scope="scope">
                <div class="">{{scope.row.user.id}}</div>
              </template>
            </el-table-column>
            <el-table-column
                label="头像"
                width="84"
            >
              <template  slot-scope="scope">
                <img :src="scope.row.user.avatar" class="five-avatar">
              </template>
            </el-table-column>
            <el-table-column
                prop="name"
                label="学员"
                width="140">
              <template  slot-scope="scope">
                <div class="">{{scope.row.user.nickname}}</div>
                <div class="">{{scope.row.nickname}}</div>
              </template>
            </el-table-column>
            <el-table-column
                label="手机号"
                width="140">
              <template  slot-scope="scope">
                <div class="">{{scope.row.user.mobile}}</div>
              </template>
            </el-table-column>
            <el-table-column
                label="学习进度"
                width="100">
              <template  slot-scope="scope">
                <div class="">{{scope.row.schedule}}%</div>
              </template>
            </el-table-column>
            <el-table-column
                prop="created_at"
                label="加入时间"
            >
            </el-table-column>

            <el-table-column
                label="操作"
                align="center"
                width="100">
              <template slot-scope="scope">
                <el-button  type="text" size="small" class="table-edit" @click="showLink(scope.row)">查看资料</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <empty-prompt v-else></empty-prompt>


        <el-pagination
            v-if="studentInfo.meta && studentInfo.meta.last_page > 1"
            class="page"
            background
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="pageSize"
            layout=" prev, pager, next,total, jumper"
            :total="studentInfo.meta ? studentInfo.meta.total : 0">
        </el-pagination>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import EmptyPrompt from "components/EmptyPrompt";
export default {
  name: "Student",
  data() {
    return {
      loading:true,
      activeName: 'student',
      uuid: '',
      courseInfo: {}, // 课程信息
      page: 1,
      pageSize: 20,
      options: '',  // 选项
      optionsData: [
        {
          label: 'ID',
          value: 'user_id'
        },
        {
          label: '昵称',
          value: 'nickname'
        },
        {
          label: '手机号',
          value: 'mobile'
        },
      ],
      keywords: '', // 关键词
      studentInfo: {},  // 用户信息
    }
  },
  created() {
    if (this.$route.params.uuid) {
      this.uuid = this.$route.params.uuid
      this.queryFun()
      this.getCourses()
      this.getStudent()
    }
  },
  methods: {
    // 获取课程信息
    getCourses() {
      let url = this.$api.Courses + '/' + this.uuid;
      this.$http.get(url, true).then(res => {
        if (res.data.success) {
          this.courseInfo = res.data.data;
          this.loading = false
        }
      }).catch(err =>{
        this.loading = false
      })
    },
    // 获取学院列表
    getStudent() {
      let url= this.$api.Courses+ '/'+ this.uuid + '/users' + `?page=${this.page}&pageSize=${this.pageSize}&options=${this.options}&keywords=${this.keywords}`;
      this.$http.get(url, true).then(res =>{
        if(res.data.success) {
          this.studentInfo= res.data.data
        }
      })
    },
    // 查询
    searchSubmit() {
      this.page= 1;
      this.linkFun();
      this.getStudent()
    },
    // 重置
    resetFun() {
      this.options= ''
      this.keywords= ''
      this.searchSubmit()
    },
    // 查看资料
    showLink(data) {
      this.$router.push({path: `/user/show/${data.user.uuid}`})
    },
    //分页
    handleCurrentChange(val) {
      this.page= val
      this.linkFun()
      this.getStudent()
    },
    // tab切换想像
    handleClick(tab, event) {
      if(tab.name === 'chapters') {
        this.$router.push({path: `/courses/class/${this.uuid}/chapters`})
      }else if(tab.name === 'media') {
        this.$router.push({path: `/courses/class/${this.uuid}/media`})
      }else if(tab.name === 'materials') {
        this.$router.push({path: `/courses/class/${this.uuid}/materials`})
      }else if(tab.name === 'interact') {
        this.$router.push({path: `/courses/class/${this.uuid}/interact`})
      }else if(tab.name === 'student') {
        this.$router.push({path: `/courses/class/${this.uuid}/student`})
      }else {
        this.$router.push({path: `/courses/courses`})
      }
    },
    // 改变路由参数
    linkFun() {
      this.$router.push({path: `/courses/class/${this.uuid}/student`,query: {
          options: this.options,
          keywords: this.keywords,
          level: this.level,
          status: this.status,
          page: this.page,
          pageSize: this.pageSize
        }})
    },
    // 参数赋值
    queryFun() {
      let query= this.$route.query;
      if(query) {
        if(query.options) {
          this.options= query.options;
        }
        if(query.keywords) {
          this.keywords= query.keywords;
        }
        if(query.level) {
          this.level= query.level;
        }
        if(query.status) {
          this.status= query.status;
        }
        if(query.page) {
          this.page= parseInt(query.page);
        }
        if(query.pageSize) {
          this.pageSize= parseInt(query.pageSize);
        }
      }
    }
  },
  components: {
    EmptyPrompt
  }
}
</script>

<style lang="less" scoped>
@import "~assets/less/courses/classHour/index.less";
.el-tab-pane{
  min-height: 528px;
}
</style>
